<template>
	<div class="page-chat">
		<div class="center">
			<lang v-if="LANGUAGE_CHANGE_SHOW" />

			<div style="display: flex; gap: 20px;">
				<h1 v-if="noEmbed" class="title">{{ $t('chat') }}</h1>

			<label class="select-container" style="height: 10px;width: 100%;" v-if="!chatOnly && PROJECT_SHOW && noEmbed">
				<ListOfProjects v-model="form.project_id"></ListOfProjects>
			</label>

			</div>


				<div style="margin-top: 10px;margin-bottom: 10px;">
					<button @click="() => this.showDocsModal = true" class="btn">Docs settings</button>
				</div>


			<div class="faq-results" v-if="questions.length">
				<div class="scroll" ref="scroll">
					<ul>
						<li v-for="(question, index) in questions" :key="index" :class="question.type">
							<div class="icon">
								<img src="@/assets/img/user.png" alt="" v-if="question.type == 'question'">
								<img src="@/assets/img/ai.png" alt="" v-else>
							</div>

							<div class="text" v-if="question.question">
								<p style="margin-bottom: 15px">Q: {{ question.question }}</p>
								<p>A:<br>
								<div>
									<p v-for="messPart of replaceLinks(escapeHtml(question.text)).split('\n')" v-html="messPart || '&nbsp;'">
									</p>
								</div>
								</p>
							</div>
							<div class="text" v-else>
								<p>
								<div>
									<p v-for="messPart of replaceLinks(escapeHtml(question.text)).split('\n')" v-html="messPart || '&nbsp;'">
									</p>
								</div>
								</p>
							</div>
						</li>
					</ul>
				</div>
			</div>

			<form action="#" class="faq-generate" ref="form" @submit.prevent="sendForm">
				<div style="display: flex; flex-direction: row; width: 100%;position: relative;">
					<input type="hidden" name="bot_id" :value="API_BOT_ID">
					<input type="text" name="prompt" class="prompt" v-model="form.text" :placeholder="$t('ask')"/>
					<button style="margin-left: auto;" type="submit" class="btn" ref="submit">
						<img width="19" src="@/assets/img/send.png">
					</button>
				</div>
			</form>

			<div v-if="!chatOnly && noEmbed" class="tx-l mt15">
				<button class="btn-link-black" @click="showEmbed = !showEmbed">
					<span>{{ $t('addFaq') }}</span>
					<!-- <img src="@/assets/img/link.svg" alt=""> -->
				</button>
			</div>

			<textarea @click="() => $refs.code.select()" ref="code"
				style="margin-top: 20px; border: 1px solid black; padding: 10px; min-width: 100%; min-height: 290px;"
				readonly v-if="showEmbed">
						{{ embedText }}
					</textarea>
		</div>
	</div>

	<div class="modal-container" style="width: 100vw; height: 100vh; position: fixed; top: 0px; left: 0; right: 0; bottom: 0" v-if="showDocsModal">
		<div class="modal" style="height: fit-content;padding: 100px;">
            <FontAwesomeIcon style="position: absolute; right:10px; top: 10px; padding: 8px;" icon="close"
                @click="() => this.showDocsModal = false"></FontAwesomeIcon>
			<div>
				<table style="min-width: fit-content; margin-left: auto; margin-right: auto">
          <thead>
            <tr>
              <th>File</th>
              <th></th>
            </tr>
          </thead>
          <tr v-for="(item, index) in getSavedKnowledge" :key="index">
            <td>{{ item.file_name }}</td>
            <td style="white-space: nowrap">
            Use in chat:
              <input
                v-model="docsChecks[item.id]"
                type="checkbox"
              />
            </td>
          </tr>
        </table>
			</div>
		</div>
	</div>

	<div class="modal-container" style="width: 100vw; height: 100vh; position: fixed; top: 0px; left: 0; right: 0; bottom: 0" v-if="showChatModal">
		<div class="modal">
		<div style="position: absolute; left:2px; top: 2px; padding: 8px;">
			<div style="margin-right: 25px;">Chat ID: {{ identifyChatOrUser }}
				<button @click.prevent="copyTextToClipboard(identifyChatOrUser)">
					<FontAwesomeIcon icon="file" />
				</button>
			</div>
		</div>
            <FontAwesomeIcon style="position: absolute; right:10px; top: 10px; padding: 8px;" icon="close"
                @click="() => this.showChatModal = false"></FontAwesomeIcon>
			<iframe class="clickup-embed clickup-dynamic-height" src="https://forms.clickup.com/20570259/f/kkr4k-4931/62GS4SIP68IDI2AVFX" onwheel="" width="100%" height="100%" style="background: transparent; border: 1px solid #ccc;"></iframe>
		</div>
	</div>

	<div style="position: absolute; right: 20px; bottom: 20px;"><button class="btn" @click="() => this.showChatModal = true">Feedback</button></div>

</template>

<script>
const sc = 'script';

import axios from '@/axios';
import lang from '@/components/LangControl';
import ListOfProjects from '@/components/Projects/ListOfProjects.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
	components: {
		lang,
		ListOfProjects,
		FontAwesomeIcon,
	},
	props: ['only_project_link'],
	data() {
		return {
			form: {
				text: null,
				project_id: this.DEFAULT_PROJECT_ID ?? null,
				project_link: null,
			},
			showEmbed: false,
			questions: [],
			noEmbed: false,
			chatOnly: false,

			conversationId: null,

			showChatModal: false,
			showDocsModal: false,
			docsChecks: {},

			responsesBeforeChat: 5,
		}
	},
	created() {
		this.noEmbed = this.$route.name !== 'embed';
		this.chatOnly = !!this.$props.only_project_link;

		if (this.$route.query.project_id) {
			this.form.project_id = this.$route.query.project_id;
		}

		if (this.$props.only_project_link) {
			this.form.project_link = this.$props.only_project_link;
		}

		this.$store.dispatch('updateProjectSavedKnowledge', { project_link: this.form.project_link, project_id: this.form.project_id });
	},
	mounted() {
		setTimeout(() => {
			this.showChatModal = true;
		}, 60_000 * 5);
	},
	watch: {
		currentProjectId() {
			this.$store.dispatch('updateProjectSavedKnowledge', { project_id: this.form.project_id });
		},
	},
	computed: {
		getSavedKnowledge() {
			let list = [];

			if (this.form.project_id) {
				list = this.$store.getters.getSavedKnowledge(this.form.project_id) ?? [];
			}

			if (this.form.project_link) {
				list = this.$store.getters.getSavedKnowledgeByLink(this.form.project_link) ?? [];
			}

			for (const item of list) {
				this.docsChecks[item.id] ??= true;
			}

			return list;
		},
		currentProjectId() {
			return this.form.project_id;
		},
		identifyChatOrUser() {
			return this.conversationId ?? this.$store.getters.getProfile?.email ?? this.form.project_link;
		},
		embedText() {
			return `
<!DOCTYPE html>
<html lang="">
  <head>
    <title>Embed example</title>
    <${sc} src="${location.origin}/embedder.js"></${sc}>
  </head>
  <body>
  </body>

  <${sc}>
    createChatWidget({project_id: ${this.form.project_id}});
  </${sc}>
</html>`;
		}
	},
	methods: {
		async copyTextToClipboard(text) {
		if (!navigator.clipboard) {
			this.$toast.error("Please, do it manually. Error occurred during copy :(");
			return;
		}

		await navigator.clipboard.writeText(text);

		this.$toast.success("Copied");
		},

		escapeHtml(unsafe) {
			return unsafe
				.replace(/&/g, "&amp;")
				.replace(/</g, "&lt;")
				.replace(/>/g, "&gt;")
				.replace(/"/g, "&quot;")
				.replace(/'/g, "&#039;");
		},

		replaceLinks(text) {
			const Rexp = /(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?\/=~_|!:,.;]*)[-A-Z0-9+&@#\/%=~_|])/ig;

			const linksReplaced = text.replace(Rexp, "<a href='$1' target='_blank'>$1</a>");
			return linksReplaced.replace(/([a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4})/ig, '<a href="mailto:$1">$1</a>');
		},
		async sendForm() {
			if (!this.form.text) {
				this.$toast.error('Message text is required!');
				return;
			}
			let question = {
				type: 'question',
				text: this.form.text
			}
			const url = API_URL + '/api/complete';
			let scrollBottom = 0;

			this.questions.push(question);
			this.$refs.submit.classList.add('preloader');

			if (this.$refs.scroll != null) {
				setTimeout(() => {
					scrollBottom = this.$refs.scroll.scrollHeight;
					this.$refs.scroll.scrollTo({
						top: scrollBottom,
						behavior: 'smooth'
					});
				}, 50);
			}

			try {
				const docsToUse = this.docsChecks && Object.entries(this.docsChecks).filter(([k,v]) => v).map(([k,v]) => +k);
				await axios.get(url, {
					params: {
						prompt: this.form.text,
						bot_id: API_BOT_ID,
						project_id: this.form.project_id,
						project_link: this.form.project_link,
						lang: this.$i18next.language.toUpperCase(),
						conversationId: this.conversationId,
						filesToUse: docsToUse,
						... !docsToUse?.length && { forceDisableDocsData: true },
					},
				}).then(result => {
					let question = {
						type: 'answer',
						text: result.data.data.answer,
						question: result.data.data?.resultData?.question ?? null,
					}

					this.conversationId = result.data.data.conversationId;

					this.questions.push(question);
					this.form.text = '';
					this.$refs.submit.classList.remove('preloader');

					if (this.$refs.scroll != null) {
						setTimeout(() => {
							scrollBottom = this.$refs.scroll.scrollHeight;
							this.$refs.scroll.scrollTo({
								top: scrollBottom,
								behavior: 'smooth'
							});

							if (--this.responsesBeforeChat === 0) {
								this.showChatModal = true;
							}
						}, 50);
					}
				});
			} catch (error) {
				console.log(error);
				this.questions.pop();
				this.$refs.submit.classList.remove('preloader');
			}
		}
	}
}
</script>

<style lang="scss">
.modal-container {
	display: flex;
	flex-direction: column;
	z-index: 10;

	.modal {
		height: 100%;
		margin: auto;
		width: 100%;
		position: relative;

		background-color: white;
	}

	@media (min-width:481px)  {
		.modal {
			height: 90%;
			margin: auto;
			width: 50%;
		}
	}
}

.select-container {
	select {
		padding: 1rem!important;
	}
}

.title {
	font-size: 18pt;
    line-height: 10px;
    padding: 24px 0;
}

.page-chat {
	a {
		color: white;
		text-decoration: underline;
	}

	// background: url(../assets/img/bg-chat.jpeg) no-repeat center top;
	background-size: cover;
	min-height: 90%;
	padding: 55px 0 75px 0;

	.faq-results {
		border: 1px solid white;
		padding: 10px;
		border-radius: 42px;
		font-size: 16px;
		background: #d8dee3;
		overflow: hidden;

		ul {
			padding: 0 0;

			li {
				display: flex;
				align-items: flex-start;
				padding: 50px 20px;
				max-width: 70%;
				width: fit-content;
				color: black;
				padding-top: 10px;
				padding-bottom: 10px;
				margin-bottom: 10px;

				.icon {
					min-width: 25px;
					line-height: 0;
					text-align: center;
					margin-right: 15px;
					width: 30px;
				}

				&:nth-child(odd) {
					background: #e3f2ff;
					display: flex;
    				flex-direction: row-reverse;
					border-radius: 50px 50px 10px 50px;
					margin-left: auto;


					.text {
						padding-top: 5px;
					}

					.icon {
						margin-left: 15px;
						margin-right: 0px;
					}
				}

				&:nth-child(even) {
					border-radius: 50px 50px 50px 10px;
					background: #e6e6e6;
				}
			}
		}
	}

	.faq-generate {
		// border: 1px solid var(--colAkcent);
		border-radius: 12px;
		// padding: 5px;
		margin-top: 17px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		// background: var(--colBg);

		input[type="text"] {
			min-height: 40px;
			padding: 0 30px;
			outline: none;
			width: 100%;
			font-weight: 400;
			font-size: 16px;
			color: black;
			margin: 0;
			border-radius: 50px;

			&::placeholder {
				color: black;
			}
		}

		.btn {
			min-width: 110px;
			border-radius: 10px;
			background: var(--colAkcent);
			padding-top: 10px;
			padding-bottom: 10px;
			color: #545454;
			width: fit-content;
			cursor: pointer;
			margin: 0;
			position: absolute;
    		right: 5px;
			border-radius: 50px;
			width: 30px;
			box-shadow: none;
			padding: 0;
			margin-top: 4px;
			height: 30px;
			min-width: 0;


			&:hover {
				// background: #fff;
			}

			&.preloader {
				pointer-events: none;
				background: #fff url(../assets/img/preloader.svg) no-repeat center;
				color: transparent;
			}
		}
	}
}
</style>