const locales = {
	en: {
		train: "Train",
		projects: "Projects",
		chat: "Chat",
		file: "File Upload",
		faqFile: "FAQ File",
		upload: "Upload",
		data: "Questions and Answers:",
		text: "Text",
		ask: "Ask AI",
		generate: "Send",
		addFaq: "Add FAQ bot to your site",
		questions: "Questions",
		answer: "Answer",
		error: "Error",
		purpose: "Purpose",
		prompt_prefix: "General prompt (common for all projects)",
		prompt_for_project_prefix: "Prompt for the project",
		prompt_for_answers: "Response modifier",
		training_data: "Training Data",
		'chats-history': "Chat history",
	},
	fr: {
	    train: "Former",
		chat: "Discuter",
		file: "Téléchargement de fichiers",
		faqFile: "FAQ Fichier",
		upload: "Télécharger",
		data: "Questions and Answers:",
		text: "Texte",
		ask: "Demander quelque chose",
		generate: "Soumettre",
		addFaq: "Ajouter un bot FAQ à votre site",
		questions: "Des questions",
		answer: "Répondre",
		error: "Erreur",
		purpose: "Purpose",
		prompt_prefix: "General prompt (common for all projects)",
		prompt_for_project_prefix: "Prompt for the project",
		prompt_for_answers: "Response modifier",
		training_data: "Training Data",
		'chats-history': "Chat history",
	}
};

export default locales;