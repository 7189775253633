import axios from "axios";
import { useToast } from "vue-toast-notification";
import { getKT } from "./store";
import router from "./router";

function reject(response) {
    if (response.response.data.message === '2FA Is required') {
        router.push({ name: 'TwoFa' });
        throw response;
    }
    if (response.response.status === 401) {
        router.push({ name: 'Login' });
        throw response;
    }

    const toast = useToast();
    const error = response.response?.data?.message;
    
    if (!error) {
        throw response;
    }

    const errors = Array.isArray(error) ? error : [error];
    toast.error(`Error:<br> ${errors.join('<br>')}`);
    throw new Error(errors)
}

axios.interceptors.response.use(
    (value) => value, 
    reject
)

axios.interceptors.request.use((config) => {
    const token = getKT();
    if (token) {
        config.headers.setAuthorization('Bearer ' + getKT());
    }
    return config;
})

export default axios;