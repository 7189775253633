<template>
    <div style="display: flex; align-items: center; justify-content: center; min-height: 100%; margin-top: 150px;">
        <router-view></router-view>
    </div>
</template>

<script>

export default {
    components: {
    },
    data() {
        return {
        }
    },
    async created() {
    },
    computed: {
    },
    watch: {
    },
    methods: {

    }
}
</script>
