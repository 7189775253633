<template>
    <div class="page-train">
        <div class="center">
            <lang  v-if="LANGUAGE_CHANGE_SHOW" />
            <div class="form">
                <label class="field field-file">
                    <AddNewProject></AddNewProject>
                </label>
            </div>

            <div class="rounded-container">
                <b style="font-size: larger; margin-rigth: 18px">Projects</b>
                <div style="height: 350px; overflow-y: auto;scrollbar-width: thin;">
                    <ListOfProjects :type-select="'table'"></ListOfProjects>
                </div>
            </div>

            <!-- <table>
                <th>
                    <td>Name</td><td></td>
                </th>
            </table> -->
        </div>
    </div>
</template>

<script>
import axios from '@/axios';
import lang from '@/components/LangControl';
import ListOfProjects from '@/components/Projects/ListOfProjects.vue';
import AddNewProject from '@/components/Projects/AddNewProject.vue';

export default {
    components: {
        lang,
        ListOfProjects,
        AddNewProject
    },
    data() {
        return {
            form: {
                file: null,
                text: '',
                project_id: null,
            },
            nameFile: 'FAQ File<br> pdf, csv.',
            message: null,
            status: false
        }
    },
    methods: {
        
    }
}
</script>

<style lang="scss">
.message {
    position: fixed;
    right: -300px;
    bottom: 15px;
    max-width: 300px;
    width: 100%;
    animation: right 0.5s forwards;

    .item {
        background: rgba(0, 0, 0, .8);
        padding: 10px;
        border-radius: 12px;
        border: 1px solid var(--colAkcent);
        font-size: 14px;
        color: #fff;
        position: relative;

        div+div {
            margin-top: 5px;
        }

        &+.item {
            margin-top: 10px;
        }
    }

    .item .close {
        position: absolute;
        top: 5px;
        right: 10px;
        cursor: pointer;
        color: #fff;
        font-size: 15px;
        line-height: 1;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
            color: var(--colAkcent);
        }
    }

    &.error {
        .item {
            border-color: red;
            color: red;
        }

        .item .close {
            color: red;

            &:hover {
                color: var(--colAkcent);
            }
        }
    }
}

@keyframes right {
    0% {
        right: -300px;
    }

    100% {
        right: 15px;
    }
}

.page-train {
    // background: url(../assets/img/bg-train.jpeg) no-repeat center top;
    background-size: cover;
    min-height: 100%;

    padding: 55px 0 75px 0;

    .center {
        max-width: 600px;
    }

    .btn {
        &.preloader {
            pointer-events: none;
            background: #fff url(../assets/img/preloader.svg) no-repeat center;
            color: transparent;
        }
    }
}
</style>
